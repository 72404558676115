<template>
	<b-modal id="transmissao-campanha" :title="title" centered size="md" @hide="reset">
		<b-container class="app-scroll-custom">
			<b-row>
				<b-col>
					<label for="identificacao-da-campanha">Identificação da campanha:</label>
					<input type="text" id="identificacao-da-campanha" v-model="campanha" class="w-100" :disabled="isSending" />
				</b-col>
			</b-row>
			<b-row class="mt-3">
				<b-col v-if="type == 'IMAGE'" cols="4">
					<label for="image-picker" class="text-truncate" title="Imagem da campanha">Imagem da campanha:</label>
					<div class="transmissao-media-picker"
						 :style="{ 'background-image': `url(${file})`, 'background-color': file == null ? '#fff' : '#333' }">
						<i v-if="!file" class="fas fa-image"></i>
						<input id="image-picker"
							   ref="image-picker"
							   type="file"
							   accept="image/*"
							   @change="pickFile($event.target)"
							   :disabled="isSending" />
						<div v-if="file && !isSending" class="transmissao-media-controls">
							<i class="fas fa-expand-arrows-alt" title="Expandir" v-b-modal.image-expand></i>
							<i class="fas fa-trash" title="Remover" @click="removeFile($refs['image-picker'])"></i>
						</div>
					</div>
				</b-col>
				<b-col v-else-if="type == 'VIDEO'" cols="4">
					<label for="video-picker" class="text-truncate" title="Vídeo da campanha">Vídeo da campanha</label>
					<div class="transmissao-media-picker"
						 :style="{ 'background-image': `url(${videoThumbnail})`, 'background-color': file == null ? '#fff' : '#333' }">
						<i v-if="!file" class="fas fa-video"></i>
						<b-spinner v-if="file && !videoThumbnail" label="Carregando..."></b-spinner>
						<input id="video-picker"
							   ref="video-picker"
							   type="file"
							   accept="video/*"
							   @change="pickFile($event.target)"
							   :disabled="isSending" />
						<div v-if="file && !isSending" class="transmissao-media-controls">
							<i class="fas fa-play" title="Reproduzir" v-b-modal.video-player></i>
							<i class="fas fa-trash" title="Remover" @click="removeFile($refs['video-picker'])"></i>
						</div>
					</div>
				</b-col>
				<b-col v-else-if="type == 'DOCUMENT'" cols="4">
					<label for="document-picker" class="text-truncate" title="Documento da campanha">Documento da campanha</label>
					<div class="transmissao-media-picker">
						<i v-if="!file" class="fas fa-folder"></i>
						<i v-else class="fas fa-file document-picker-extension">
							<span>{{$refs["document-picker"].files[0].name.split(".").slice(-1)[0]}}</span>
						</i>
						<div v-if="file" class="document-picker-filename text-truncate">
							{{$refs["document-picker"].files[0].name}}
						</div>
						<input id="document-picker"
							   ref="document-picker"
							   type="file"
							   @change="pickFile($event.target)"
							   :disabled="isSending" />
						<div v-if="file && !isSending" class="transmissao-media-controls">
							<i class="fas fa-external-link-alt" title="Abrir" @click="openFile"></i>
							<i class="fas fa-trash" title="Remover" @click="removeFile($refs['document-picker'])"></i>
						</div>
					</div>
				</b-col>
				
				<b-col>
					<label>Mensagem da campanha:</label>
					<div :class="`whatsbusiness-message-box${isSending ? ' disabled' : ''}`">
						<svg viewBox="0 0 8 13" width="8" height="13">
							<path opacity=".13" d="M5.188 1H0v11.193l6.467-8.625C7.526 2.156 6.958 1 5.188 1z"></path>
							<path fill="#d9fdd3" d="M5.188 0H0v11.193l6.467-8.625C7.526 1.156 6.958 0 5.188 0z"></path>
						</svg>
						<div v-html="htmlContent" class="app-scroll-custom"></div>
					</div>
				</b-col>
			</b-row>
			<b-row v-if="canalTransmissao === 2">
				<b-col>
					<label>Assunto do email:</label>
					<input type="text" id="identificacao-da-campanha" v-model="assuntoEmail" class="w-100" :disabled="isSending" />
				</b-col>
			</b-row>
			<b-row v-for="(param, index) in paramsKeys" class="mt-3" align-v="end">
				<b-col>
					<label :for="`valor-parametro-${param.key.match(/\d+/g)[0]}`">Valor do parâmetro {{param.key.match(/\d+/g)[0]}}:</label>
					<select :id="`valor-parametro-${param.key.match(/\d+/g)[0]}`" v-model="param.type" class="w-100" :disabled="isSending">
						<option value="text">Texto</option>
						<option value="customer_data">Dado do Cliente</option>
						<option v-if="recipients.source == 'excel-file'" value="excel_data">Coluna do Excel</option>
					</select>
				</b-col>
				<b-col cols="8">
					<label>&nbsp;</label>
					<input v-if="param.type == 'text'"
					type="text"
					placeholder="Insira aqui o texto..."
					v-model="params[index]"
					class="w-100"
					:disabled="isSending" />
					<select v-if="param.type == 'customer_data'" v-model="params[index]" class="w-100" :disabled="isSending">
						<option value="{CLIENTE_CNPJ_CPF}">CNPJ/CPF</option>
						<option value="{CLIENTE_RAZAOSOCIAL_NOME}">Nome/Razão Social</option>
						<option value="{CLIENTE_NOME_FANTASIA}">Apelido/Nome Fantasia</option>
						<option value="{CLIENTE_NOME_CONTATO}">Nome de Contato</option>
						<option value="{CLIENTE_EMAIL}">E-mail</option>
						<option value="{CLIENTE_TELEFONE}">Telefone</option>
						<option value="{CLIENTE_CIDADE}">Cidade</option>
						<option value="{CLIENTE_ESTADO}">Estado</option>
					</select>
					<select v-if="param.type == 'excel_data'" v-model="params[index]" class="w-100" :disabled="isSending">
						<option v-for="field in recipients.fields" :key="`excel_data-${field.field}`" :value="`{CUSTOM:${field.field}}`">{{field.label}}</option>
					</select>
				</b-col>
				<!--<b-col v-if="tipoCanal == 5">
					<b-form-checkbox v-b-tooltip.hover title="Informe se deseja omitir parte deste dado" v-model="paramsOmitir[index]"
					class="size-font-class-label"
					required>
							Omitir
					</b-form-checkbox>
				</b-col>-->
			</b-row>
			<b-row v-if="setores && setores.length > 0" class="mt-3">
				<b-col>
					<label for="setor-de-recebimento">Setor de recebimento:</label>
					<select id="setor-de-recebimento" v-model="setor" class="w-100" :disabled="isSending || statusConexao == 'loading'">
						<option v-for="setor in setores" :value="setor.Id">{{setor.Nome}}</option>
					</select>
				</b-col>
				<b-col v-if="tipoCanal == '9' && statusConexao != 'waiting'" cols="12" md="auto" class="pl-0">
					<i v-if="statusConexao == 'loading'" class="fas fa-circle-notch fa-spin text-primary mt-4"></i>
					<i v-if="statusConexao == 'disconnected'" class="fas fa-exclamation-triangle text-warning mt-4" title="O número do setor está desconectado."></i>
					<i v-if="statusConexao == 'connected'" class="fas fa-wifi text-success mt-4" title="O número do setor está conectado."></i>
				</b-col>
			</b-row>
			<b-row v-if="setores && setores.length > 0 && setor" class="mt-3">
				<b-col>
					<label for="canal-de-recebimento">Canal de recebimento:</label>
					<select id="canal-de-recebimento" v-model="canal" class="w-100" :disabled="isSending || !canais || canais.length == 0">
						<option v-if="isCanaisLoading" :value="null">Carregando...</option>
						<option v-else-if="!canais || canais.length == 0" :value="null">Nenhum canal disponível no momento</option>
						<option v-for="canal in canais" :value="canal.Id" :disabled="canal.UsuariosAptos == 0">{{canal.Nome}} ({{canal.UsuariosAptos}} usuários)</option>
					</select>
				</b-col>
			</b-row>
			<b-row v-if="setores && setores.length > 0 && setor && tipoCanal == 5" class="mt-3">
				<b-col>
					<label for="canal-de-recebimento">Centro de custo:</label>
					<select id="canal-de-recebimento" v-model="centroDeCustoId" class="w-100" :disabled="isSending || !centrosDeCusto || centrosDeCusto.length == 0">
						<option v-if="isCCLoading" :value="null">Carregando...</option>
						<option v-else-if="!centrosDeCusto || centrosDeCusto.length == 0" :value="null">Não há centros de custo disponíveis</option>
						<option v-for="CC in centrosDeCusto" :value="CC.Id">{{CC.Nome}}</option>
					</select>
				</b-col>
			</b-row>
			<b-row v-if="centroDeCusto" class="mt-3">
				<b-col>
					<label for="canal-de-recebimento">Produto:</label>
					<select id="canal-de-recebimento" v-model="produto" class="w-100" :disabled="isSending || !centroDeCusto || centroDeCusto.Produtos.length == 0">
						<option v-if="isCCLoading" :value="null">Carregando...</option>
						<option v-else-if="!centroDeCusto.Produtos || centroDeCusto.Produtos.length == 0" :value="null">Não há produtos disponíveis</option>
						<option v-for="produto in centroDeCusto.Produtos" :value="produto.Id">{{produto.Nome}}</option>
					</select>
				</b-col>
			</b-row>
			<b-row v-if="chatPerguntas && chatPerguntas.length > 0 && canalTransmissao != 2" class="mt-3">
				<b-col>
					<label for="arvores-compativeis">Vincular à árvore de decisões:</label>
					<select id="arvores-compativeis" v-model="chatPergunta" class="w-100" :disabled="isSending">
						<option :value="null"></option>
						<option v-for="chatPergunta in chatPerguntas" :value="chatPergunta.ChatPerguntaId">{{chatPergunta.Arvore}}</option>
					</select>
				</b-col>
			</b-row>
			<b-row class="mt-3">
				<b-col>
					<label for="validade-da-campanha">Validade da campanha:</label>
					<input type="datetime-local" id="validade-da-campanha" v-model="validade" class="w-100" :disabled="isSending" />
				</b-col>
			</b-row>
			<b-row class="mt-3">
				<b-col>
					<label for="disparo-irrestrito">Para clientes com atendimento ativo/pendente no canal:</label>
					<select id="disparo-irrestrito" v-model="disparoIrrestrito" class="w-100">
						<option :value="false">Não disparar para este cliente</option>
						<option :value="true">Forçar disparo</option>
					</select>
				</b-col>
			</b-row>
		</b-container>
		<template #modal-footer="{ cancel }">
			<b-button v-if="!isSending" variant="light" @click="cancel()" class="rounded-0">Cancelar</b-button>
			<b-button variant="success"
					  @click="send"
					  class="rounded-0"
					  :disabled="!isValid || isSending">
				<i v-if="isSending" class="fas fa-circle-notch fa-spin"></i>
				{{isSending ? "&nbsp;Transmitindo..." : "Transmitir"}}
			</b-button>
		</template>
		<b-modal id="image-expand" hide-header hide-footer body-bg-variant="dark" size="lg" centered>
			<img :src="file" class="w-100" />
		</b-modal>
		<b-modal id="video-player" hide-header hide-footer body-bg-variant="dark" size="lg" centered>
			<video :src="file" class="w-100" controls autoplay></video>
		</b-modal>
	</b-modal>
</template>
<script>
	import axios from "axios";
	export default {
		name: "ModalTransmissaoCampanha",
        props: {
            canalTransmissao: null
        },
		data() {
			return {
				id: null,
				type: null,
				params: [],
				file: null,
				fileName: null,
				title: null,
				paramsKeys: [],
				content: null,
				htmlContent: null,
				videoThumbnail: null,
				isSending: false,
				recipients: {},
				campanha: null,
				validade: null,
				disparoIrrestrito: false,
				setores: null,
				paramsOmitir: [],
				setor: null,
				canais: null,
				centrosDeCusto: null,
				centroDeCusto: null,
				centroDeCustoId: null,
				isCCLoading: false,
				produto: null,
				canal: null,
				isCanaisLoading: false,
				chatPerguntas: null,
				chatPergunta: null,
				tipoCanal: null,
				additionalInfo: null,
				statusConexao: null,
				botoes: [],
                assuntoEmail: null
			}
		},
		computed: {
			isValid() {
				let result =
					this.paramsKeys.length == this.params.filter(item => item && item.length > 0).length && (["IMAGE", "VIDEO", "DOCUMENT"].includes(this.type) ?
                        this.file != null : true) && this.setor && this.canal && this.validade && (this.statusConexao ? this.statusConexao == "connected" : true) &&  (this.canalTransmissao === 2 ? this.assuntoEmail?.length > 3 : true ) && (this.tipoCanal == 5 ? (this.centrosDeCusto && this.centroDeCustoId && this.centroDeCusto && this.produto) : true);
					
                return result; 						
			}
		},
		watch: {
			centroDeCustoId(){
				if (this.centrosDeCusto && this.centrosDeCusto.length > 0) this.centroDeCusto = this.centrosDeCusto.filter(CC => CC.Id == this.centroDeCustoId)[0];
			},
			content() {
				this.updateHtmlContent();
			},
			params() {
				this.updateHtmlContent();
			},
			setor() {
				if (this.setor == null) return;
				if (this.tipoCanal == 9) {
					this.statusConexao = "loading";
					axios.get(`/api/setor/isconnected/${this.setor}`).then(response => {
						this.statusConexao = response.data ? 'connected' : 'disconnected';
					}).catch(() => {
						this.statusConexao = "disconnected";
					});
				}
				if(this.tipoCanal == 5) this.buscaCentroDeCusto(this.setor);
				this.canais = null;
				this.canal = null;
				this.isCanaisLoading = true;
				axios.get(`/api/campanhas/canais-disponiveis/${this.tipoCanal}?setorId=${this.setor}`).then(response => {
					this.canais = response.data;
					if (this.canais.length == 1 && this.canais[0].UsuariosAptos > 0) this.canal = this.canais[0].Id;
					this.isCanaisLoading = false;
				}).catch(() => {
					this.isCanaisLoading = false;
				});
			}
		},
		methods: {
			updateHtmlContent() {
				if (!this.content) return;
				let content = this.content;
				this.paramsKeys.forEach((item, index) => {
					content = content.replace(item.key, this.params[index] || item.key);
				});
				this.htmlContent = content
					.replace(/\n/g, "<br />")
					.replace(/( \_?\~?)(\*)(.*?)(\*)/g, "$1<b>$3</b>")
					.replace(/( \*?\~?)(\_)(.*?)(\_)/g, "$1<i>$3</i>")
					.replace(/( \*?\_?)(\~)(.*?)(\~)/g, "$1<del>$3</del>");
			},
			pickFile(input) {
				if (input.files.length == 0) {
					this.removeFile();
					return;
				}
				let templateTypes = {
					IMAGE: { label: "A imagem", maxSize: 2 },
					VIDEO: { label: "O vídeo", maxSize: 20 },
					DOCUMENT: { label: "O documento", maxSize: 2 }
				};
				if (input.files[0].size / 1048576 > templateTypes[this.type].maxSize) {
					this.$bvModal.msgBoxOk(`${templateTypes[this.type].label} é maior do que o tamanho permitido (${templateTypes[this.type].maxSize}MB).`, {
						title: "Limite excedido...",
						size: "md",
						buttonSize: "sm",
						okVariant: "danger",
						okTitle: "Entendi"
					}).then(() => {
						this.removeFile(input);
					});
					return;
				}
				this.file = URL.createObjectURL(input.files[0]);
				this.fileName = input.files[0].name;
				if (this.type == "VIDEO") {
					let video = document.createElement("video"),
						canva = document.createElement("canvas");
					video.onseeked = () => {
						canva.width = video.videoWidth;
						canva.height = video.videoHeight;
						canva.getContext("2d").drawImage(video, 0, 0, canva.width, canva.height);
						canva.toBlob(blob => {
							this.videoThumbnail = URL.createObjectURL(blob);
							video.remove();
							canva.remove();
						}, "image/jpeg", 1);
					};
					video.onloadedmetadata = () => {
						setTimeout(() => {
							video.currentTime = 0;
							if (video.duration >= 1) video.currentTime = 1;
						}, 200);
					};
					video.src = URL.createObjectURL(this.$refs["video-picker"].files[0]);
					video.load();
				}
			},
			removeFile(input) {
				this.file = null;
				this.fileName = null;
				this.videoThumbnail = null;
				if (input) input.value = null;
			},
			openFile() {
				if (this.file) window.open(this.file);
			},
			open(template, recipients, tipoCanal) {
				this.title = template.title;
				this.type = template.type;
				this.content = template.content;
				this.additionalInfo = template.additionalInfo;
				this.paramsKeys = (this.content.match(/\{\{\d+\}\}/g) ?? []).map(item => ({
					key: item,
					type: "text"
				}));
				this.recipients = recipients;
				this.tipoCanal = tipoCanal;
				this.setor = template.setorId;
				if (this.setor) {
					axios.get(`/api/setor/${this.setor}`).then(response => {
						this.setores = [
							{ Id: this.setor, Nome: `${response.data.Nome}${this.tipoCanal == 15 ? ` (BM ${response.data.App} - ${response.data.NumeroBM })` : ""}` }
						];
						if (this.setores.length == 1) this.setor = this.setores[0].Id;
						console.log(this.setores)
					});
				} else {
					axios.get(`/api/campanhas/setores-disponiveis/${tipoCanal}`).then(response => {
						this.setores = response.data;
						if (this.setores.length == 1) this.setor = this.setores[0].Id;
					});
				}
                axios.post(`/api/campanhas/arvores-compativeis`, { template: this.content.replace(/<(\s*\/?)[^>]+>/g, ' ') }).then(response => {
                    this.chatPerguntas = response.data;
				});
				if (tipoCanal == 9) this.statusConexao = "waiting";
				this.$bvModal.show("transmissao-campanha");
			},
			async send() {
				this.isSending = true;
				if (this.tipoCanal == 9) {
					let isConnected = await axios.get(`/api/setor/isconnected/${this.setor}`).then(response => {
						this.statusConexao = response.data ? 'connected' : 'disconnected';
					}).then(() => {
						if (this.statusConexao == "connected") return true;
						return false;
					}).catch(() => {
						this.statusConexao = "disconnected";
						return false;
					});
					if (!isConnected) {
						this.isSending = false;
						this.$bvToast.toast(`Não foi possível transmitir a campanha no momento porque o canal de transmissão está inacessível.`, {
							title: "Desconectado",
							variant: "danger",
							solid: true
						});
						return;
					}
				}
				let data = new FormData();
				data.append("template", this.content);
				if (this.campanha?.trim()) data.append("campanha", this.campanha);
				
				data.append("type", this.type);
				console.log(this.params)
				this.params.forEach(param => data.append("params[]", param))
				data.append("source", this.recipients.source);
				if (this.recipients.source == "clientes-selection") data.append("clientes", JSON.stringify(this.recipients.clientes));
				if (this.recipients.source == "excel-file") {
					this.recipients.fields.map(field => field.field).forEach(field => data.append("fields[]", field));
					data.append("rows", JSON.stringify(this.recipients.rows));
				}
				if (this.file) data.append("file", await fetch(this.file).then(content => content.blob()).then(blob => blob), this.fileName);
				data.append("setor", this.setor);
				data.append("canal", this.canal);
				if (this.chatPergunta) data.append("chatPerguntaId", this.chatPergunta);
				data.append("validade", this.validade);
				data.append("disparoIrrestrito", this.disparoIrrestrito);
                data.append("urlFront", location.origin);
				if (this.canalTransmissao) {
					this.additionalInfo.AssuntoEmail = this.assuntoEmail;
                }
				if(this.centroDeCustoId && this.produto){
					this.additionalInfo.Produto = JSON.stringify(this.produto);
					this.additionalInfo.CentroDeCusto = JSON.stringify(this.centroDeCustoId);
				}
				if (this.additionalInfo) data.append("additionalInfo", JSON.stringify(this.additionalInfo));
				axios.post("/api/campanhas/criar-campanha", data, {
					headers: { "Content-Type": "multipart/form-data" }
				}).then(response => {
					let total = response.data.total;
					this.$bvToast.toast(`Estamos registrando o total de ${total} destinatário${total > 1 ? "s" : ""} e a transmissão das mensagens se iniciará em instantes. Você pode acompanhar o progresso da campanha na página de Consultas > Histórico de Campanhas.`, {
						title: "Transmissão em andamento",
						variant: "success",
						solid: true
					});
					this.$bvModal.hide("transmissao-campanha");
				}).catch(response => {
					this.isSending = false;
					this.$bvToast.toast(`Não foi possível transmitir a campanha no momento. Mensagem de erro: ${response}`, {
						title: "Algo deu errado...",
						variant: "danger",
						solid: true
					});
				});
			},
			buscaCentroDeCusto(setorId){
				this.isCCLoading = true;
				axios.get(`/api/SMS/setores/${setorId}/centros-de-custo`)
				.then(res => {
					this.centrosDeCusto = res.data;
					if(this.centrosDeCusto.length == 1){
						this.centroDeCustoId = this.centrosDeCusto[0].Id;
						this.centroDeCusto = this.centrosDeCusto[0];
					}
				})
				.catch(error => {
					console.error(error)
				})
				.finally(() => this.isCCLoading = false);
			},
			reset() {
				setTimeout(() => {
					this.title = null;
					this.type = null;
					this.paramsKeys = [];
					this.params = [];
					this.content = null;
					this.file = null;
					this.fileName = null;
					this.videoThumbnail = null;
					this.isSending = false;
					this.produto = null;
					this.centroDeCusto = null;
					this.centrosDeCusto = null;
					this.centroDeCustoId = null;
					this.recipients = {};
					this.campanha = null;
					this.validade = null;
					this.disparoIrrestrito = false;
					this.setores = null;
					this.setor = null;
					this.canais = null;
					this.isCanaisLoading = false;
					this.canal = null;
					this.chatPerguntas = null;
					this.chatPergunta = null;
					this.tipoCanal = null;
					this.additionalInfo = null;
					this.statusConexao = null;
					this.assuntoEmail = null;
				}, 300);
			}
		}
	}
</script>
<style scoped>
	#transmissao-campanha > .app-scroll-custom {
		max-height: 70vh;
	}

	label {
		display: block;
		color: var(--cinza-5);
		font-weight: bold;
		font-size: 12px;
		margin-bottom: 0;
	}

	input, select {
		border: 1px var(--cinza-4) solid;
		background-color: var(--cinza-2);
		font-size: 14px;
		padding: 5px 7px;
		color: var(--cinza-5);
		outline-style: none;
		transition: all ease-in-out .3s;
	}

		input:focus, select:focus {
			filter: brightness(1.03);
		}

	.transmissao-media-picker {
		position: relative;
		height: 100px;
		border: 1px var(--cinza-4) solid;
		background-position: center;
		background-size: cover;
		background-repeat: no-repeat;
		transition: all ease-in-out .3s;
	}

		.transmissao-media-picker > i {
			position: absolute;
			font-size: 40px;
			line-height: 40px;
			color: var(--cinza-3);
			top: 30px;
			left: 40px;
			animation: fade-down ease-in-out .3s;
		}

		.transmissao-media-picker > .spinner-border {
			position: absolute;
			line-height: 40px;
			color: #fff;
			top: 30px;
			left: 45px;
		}

		.transmissao-media-picker > input[type=file] {
			position: absolute;
			width: 100%;
			height: 100%;
			opacity: 0;
		}

		.transmissao-media-picker > i.document-picker-extension {
			font-size: 50px;
			left: 42px;
			color: var(--cor-primaria-cliente);
		}

			.transmissao-media-picker > i.document-picker-extension > span {
				position: absolute;
				width: 100%;
				text-align: center;
				left: 0;
				font-size: 15px;
				color: #fff;
			}

		.transmissao-media-picker > .document-picker-filename {
			position: absolute;
			width: 100%;
			bottom: 0;
			font-size: 10px;
			padding: 0 3px;
			text-align: center;
		}

	.transmissao-media-controls {
		position: absolute;
		font-size: 14px;
		color: #fff;
		right: 0;
	}

		.transmissao-media-controls > i {
			margin-right: 7px;
			font-weight: 900;
			cursor: pointer;
			text-shadow: 0 0 3px #333;
		}

	#document-picker + .transmissao-media-controls {
		color: #333;
	}

		#document-picker + .transmissao-media-controls > i {
			text-shadow: none;
		}

	@keyframes fade-down {
		from {
			opacity: 0;
			margin-top: -12px;
		}

		to {
			opacity: 1;
			margin-top: 0;
		}
	}

	.whatsbusiness-message-box {
		position: relative;
		padding: 6px 7px 8px 9px;
		background-color: #d9fdd3;
		border-radius: 7.5px;
		border-top-right-radius: 0;
		color: #111b21;
		font-size: 14.2px;
		line-height: 19px;
		box-shadow: 0 1px .5px rgba(11, 20, 26, .13);
		height: calc(100% - 18px);
	}

		.whatsbusiness-message-box.disabled {
			color: var(--cinza-5);
			opacity: .8;
		}

		.whatsbusiness-message-box > svg {
			position: absolute;
			top: 0;
			right: -8px;
		}

		.whatsbusiness-message-box > div {
			display: block;
			max-height: 86px;
			overflow: auto;
		}
</style>